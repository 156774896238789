import AxiosInstance from './AxiosInstance';

async function sendQuestion(id_respondant: number, id_question: number, time_question: number) {
    const response = await AxiosInstance.post('/respondant-question/send-question', { id_respondant, id_question, time_question});

    return response;
}

export default {
    sendQuestion
}