import possibilitie from "./models/Possibilitie";
import question from "./models/Question";
import Result from "./models/Result";
function algo(
  noQuestion: number,
  userChoice: possibilitie,
  questionsUser: Result[],
  questions: question[],
  changeQuestionType1: Function,
  changeQuestionType2: Function,
  lastStep: Function,
  changeToQuestionOrLastStepIfYes: Function
): number {
  //Valeur dans laquelle on met true si il y a au moins un oui dans les réponses à choix multiples (type 2) au faux s'il y en a pas
  let yesChecked: boolean;
  //Valeur qu'on va retourner à la fin de la fonction et qui est modifiée selon le cas
  let nextQuestion = 0;
  //Permet de garder la question à laquelle on est rendu en mémoire dans un cookie
  function setNextQuestion(noNextQuestion: number) {
    nextQuestion = noNextQuestion;
  }
  switch (noQuestion) {
    //  ****IMPORTANT***** Le numéro de question correspond à son index dans le tableau de questions dans le state du questionnaire.tsx et non à son id
    // normalement, son index est son id+1
    case 0:
      //12 ans et plus
      if (userChoice.id === 4) {
        setNextQuestion(1);
        changeQuestionType1();
      }
      //5 mois à 4 ans
      else if (userChoice.id === 2) {
        setNextQuestion(22);
        changeQuestionType1();
      }
      //5 ans à 11 ans
      else if (userChoice.id === 3) {
        setNextQuestion(42);
        changeQuestionType1();
      }
      //4 mois et moins
      else if (userChoice.id === 1) {
        lastStep(questions[noQuestion].messages[0]);
      }
      break;
    //Segment adulte 12 ans et plus
    case 1:
      setNextQuestion(2);
      changeQuestionType1();
      break;
    case 2:
      //True si il y a un oui parmi les réponses
      yesChecked = changeQuestionType2();
      if (yesChecked) {
        setNextQuestion(5);
      }
      //L'action dépend aussi de la réponse à la question précécente
      else if (
        questionsUser[1].answer.title === "Oui" ||
        questionsUser[1].answer.title === "Yes"
      ) {
        setNextQuestion(3);
      } else {
        lastStep(questions[noQuestion].messages[0]);
      }
      break;
    case 3:
      setNextQuestion(4);
      changeToQuestionOrLastStepIfYes();
      break;
    case 4:
      setNextQuestion(21);
      changeToQuestionOrLastStepIfYes();
      break;
    case 5:
      setNextQuestion(6);
      changeToQuestionOrLastStepIfYes();
      break;
    case 6:
      setNextQuestion(7);
      changeToQuestionOrLastStepIfYes();
      break;
    case 7:
      setNextQuestion(8);
      changeToQuestionOrLastStepIfYes();
      break;
    case 8:
      setNextQuestion(9);
      changeToQuestionOrLastStepIfYes();
      break;
    case 9:
      setNextQuestion(10);
      changeToQuestionOrLastStepIfYes();
      break;
    case 10:
      setNextQuestion(11);
      changeToQuestionOrLastStepIfYes();
      break;
    case 11:
      setNextQuestion(12);
      changeToQuestionOrLastStepIfYes();
      break;
    case 12:
      setNextQuestion(13);
      changeToQuestionOrLastStepIfYes();
      break;
    case 13:
      setNextQuestion(14);
      changeToQuestionOrLastStepIfYes();
      break;
    case 14:
      setNextQuestion(15);
      changeToQuestionOrLastStepIfYes();
      break;
    case 15:
      setNextQuestion(16);
      changeToQuestionOrLastStepIfYes();
      break;
    case 16:
      setNextQuestion(61);
      changeToQuestionOrLastStepIfYes();
      break;
    case 61:
      setNextQuestion(17);
      changeToQuestionOrLastStepIfYes();
      break;
    case 17:
      setNextQuestion(18);
      changeToQuestionOrLastStepIfYes();
      break;
    case 18:
      setNextQuestion(19);
      changeToQuestionOrLastStepIfYes();
      break;
    case 19:
      setNextQuestion(20);
      changeToQuestionOrLastStepIfYes();
      break;
    case 20:
      setNextQuestion(21);
      changeToQuestionOrLastStepIfYes();
      break;
    case 21:
      yesChecked = changeQuestionType2();
      //Condition nécessaire pour éviter les bug lorsque l'on clique sur suivant à la question précédente
      if (questions[noQuestion].messages.length === 2) {
        if (yesChecked) {
          lastStep(questions[noQuestion].messages[0]);
        } else {
          lastStep(questions[noQuestion].messages[1]);
        }
      }
      break;
    //Segment bébé 5 mois à 4 ans
    case 22:
      setNextQuestion(23);
      changeQuestionType1();
      break;
    case 23:
      yesChecked = changeQuestionType2();
      if (yesChecked) {
        setNextQuestion(26);
      } else if (
        questionsUser[1].answer.title === "Oui" ||
        questionsUser[1].answer.title === "Yes"
      ) {
        setNextQuestion(24);
      } else {
        lastStep(questions[noQuestion].messages[0]);
      }
      break;
    case 24:
      setNextQuestion(25);
      changeToQuestionOrLastStepIfYes();
      break;
    case 25:
      setNextQuestion(41);
      changeToQuestionOrLastStepIfYes();
      break;
    case 26:
      setNextQuestion(27);
      changeToQuestionOrLastStepIfYes();
      break;
    case 27:
      setNextQuestion(28);
      changeToQuestionOrLastStepIfYes();
      break;
    case 28:
      setNextQuestion(29);
      changeToQuestionOrLastStepIfYes();
      break;
    case 29:
      setNextQuestion(30);
      changeToQuestionOrLastStepIfYes();
      break;
    case 30:
      setNextQuestion(31);
      changeToQuestionOrLastStepIfYes();
      break;
    case 31:
      setNextQuestion(32);
      changeToQuestionOrLastStepIfYes();
      break;
    case 32:
      setNextQuestion(33);
      changeToQuestionOrLastStepIfYes();
      break;
    case 33:
      setNextQuestion(34);
      changeToQuestionOrLastStepIfYes();
      break;
    case 34:
      setNextQuestion(35);
      changeToQuestionOrLastStepIfYes();
      break;
    case 35:
      setNextQuestion(36);
      changeToQuestionOrLastStepIfYes();
      break;
    case 36:
      setNextQuestion(37);
      changeToQuestionOrLastStepIfYes();
      break;
    case 37:
      setNextQuestion(38);
      changeToQuestionOrLastStepIfYes();
      break;
    case 38:
      setNextQuestion(39);
      changeToQuestionOrLastStepIfYes();
      break;
    case 39:
      setNextQuestion(40);
      changeToQuestionOrLastStepIfYes();
      break;
    case 40:
      setNextQuestion(41);
      changeToQuestionOrLastStepIfYes();
      break;
    case 41:
      yesChecked = changeQuestionType2();
      if (yesChecked && questions[noQuestion].messages.length === 2) {
        lastStep(questions[noQuestion].messages[1]);
      } else {
        lastStep(questions[noQuestion].messages[0]);
      }
      break;
    //Segment enfant 5 ans à 11 ans
    case 42:
      setNextQuestion(43);
      changeToQuestionOrLastStepIfYes();
      break;
    case 43:
      yesChecked = changeQuestionType2();
      if (yesChecked) {
        setNextQuestion(46);
      } else if (
        questionsUser[1].answer.title === "Oui" ||
        questionsUser[1].answer.title === "Yes"
      ) {
        setNextQuestion(44);
      } else {
        lastStep(questions[noQuestion].messages[0]);
      }
      break;
    case 44:
      setNextQuestion(45);
      changeToQuestionOrLastStepIfYes();
      break;
    case 45:
      setNextQuestion(60);
      changeToQuestionOrLastStepIfYes();
      break;
    case 46:
      setNextQuestion(47);
      changeToQuestionOrLastStepIfYes();
      break;
    case 47:
      setNextQuestion(48);
      changeToQuestionOrLastStepIfYes();
      break;
    case 48:
      setNextQuestion(49);
      changeToQuestionOrLastStepIfYes();
      break;
    case 49:
      setNextQuestion(50);
      changeToQuestionOrLastStepIfYes();
      break;
    case 50:
      setNextQuestion(51);
      changeToQuestionOrLastStepIfYes();
      break;
    case 51:
      setNextQuestion(52);
      changeToQuestionOrLastStepIfYes();
      break;
    case 52:
      setNextQuestion(53);
      changeToQuestionOrLastStepIfYes();
      break;
    case 53:
      setNextQuestion(54);
      changeToQuestionOrLastStepIfYes();
      break;
    case 54:
      setNextQuestion(55);
      changeToQuestionOrLastStepIfYes();
      break;
    case 55:
      setNextQuestion(56);
      changeToQuestionOrLastStepIfYes();
      break;
    case 56:
      setNextQuestion(57);
      changeToQuestionOrLastStepIfYes();
      break;
    case 57:
      setNextQuestion(58);
      changeToQuestionOrLastStepIfYes();
      break;
    case 58:
      setNextQuestion(59);
      changeToQuestionOrLastStepIfYes();
      break;
    case 59:
      setNextQuestion(60);
      changeToQuestionOrLastStepIfYes();
      break;
    case 60:
      yesChecked = changeQuestionType2();
      if (yesChecked && questions[noQuestion].messages.length === 2) {
        lastStep(questions[noQuestion].messages[1]);
      } else {
        lastStep(questions[noQuestion].messages[0]);
      }
      break;
  }
  return nextQuestion;
}
export default algo;
