import React from "react";
import Question from "./Question";
import ButtonBack from "./ButtonBack";
import ButtonGoHome from "./ButtonGoHome";
import End from "./End";
import Welcome from "./Welcome";
import { useState } from "react";
import Block from "./Block";
import algo from "../algo";
import { setCookie, getCookie, deleteCookie } from "../Cookies";
import question from "../models/Question";
import Possibilitie from "../models/Possibilitie";
import QuestionsApi from "../apis/QuestionsApi";
import Message from "../models/Message";
import sendData from "../operationsBD";
import AnswerRadioButton from "../models/AnswerRadioButton";
import Result from "../models/Result";
import "../css/Questionnaire.css";
import DeleteAnswersApi from "../apis/DeleteAnswersApi";

//Sert à retenir les questions et réponses qui ont été demandées dans un cookie
let cookieQuestionsUser = getCookie("questionsUser");
//Contient les index des questions qui ont été demandées, le temps que ça a pris pour y répondre et la réponse à la question
//(Sert à pouvoir mettre les bonnes questions dans la bd et à faire fonctionner le bouton retour)
let questionsUser: Array<Result> =
  cookieQuestionsUser === undefined ? [] : JSON.parse(cookieQuestionsUser);

//Permet de contenir combien de temps ça a pris pour répondre à la question
let timeQuestion = 0;

let tabIdRespondantQuestion: Array<number> = [];

function Questionnaire() {
  //Permet de récupérer l'id du répondant
  let [idRespondant, setIdRespondant] = React.useState(0);
  let [uuidRespondant, setUuidRespondant] = React.useState(0);
  let [saveProgress, setSaveProgress] = React.useState(false);
  //Permet de savoir si les réponses ont bien été envoyées à la bd
  let [sendDataFinished, setSendDataFinished] = useState(false);
  //Cookie contenant la langue choisie par l'utilisateur
  let cookieLang = getCookie("lang");
  //Permet de setter la langue de l'utilisateur
  let [lang, setLang] = React.useState(
    cookieLang === undefined ? "fr" : cookieLang
  );
  //Va contenir les questions
  const [questions, setQuestions] = React.useState<question[]>([]);
  //Sert à retenir le numéro de la question dans un cookie
  let cookieNoQuestion = getCookie("noQuestion");
  //Sert à changer les numéros de question
  let [noQuestion, setNoQuestionWithoutSaving] = useState(
    cookieNoQuestion === undefined ? 0 : Number(cookieNoQuestion)
  );
  const setNoQuestion = (no: number) => {
    setNoQuestionWithoutSaving(no);
    saveProgress && setCookie("noQuestion", no.toString());
  };
  //Sert à recueillir les données des réponses sélectionnées par l'utilisateur (Question type 1)
  let [userChoice, setUserChoice] = useState({ id: 0, title: "" });
  //Permet de garder les réponses des boutons radio (Question type 2)
  let [answersRadioBtn, setAnswersRadioBtn] = useState<AnswerRadioButton[]>([]);
  //Permet de cacher ou d'exposer le message d'erreur bleu en haut lorsqu'on
  //clique sur suivant et qu'aucune réponse n'est sélectionnée
  let [messageErrorHidden, setMessageErrorHidden] = useState(true);
  //Sert à retenir l'étape si on est rendu
  let cookieStep = getCookie("step");
  //Sert à savoir à quelle étape on est rendu
  // 1 : Accueil (Welcome.tsx)
  // 2 : Questionnaire (Questionnaire.tsx)
  // 3 : Message (End.tsx)
  let [step, setStep] = useState(
    cookieStep === undefined ? 1 : Number(cookieStep)
  );
  //Permet de setter le message de fin
  let [messageEnd, setMessageEnd] = useState<Message>({ id: 0, message: "" });
  let [timestampBeginning, setTimestampBeginning] = useState(
    step === 1 ? 0 : Date.now()
  );

  //On va chercher les questions, réponses et messages de fin dans la bd
  async function getQuestion(lang: string) {
    try {
      const thequestions = await QuestionsApi.getQuestions(lang);
      setQuestions(thequestions);
    } catch (err) {
      console.error(err);
    }
  }
  React.useEffect(() => {
    if (step > 1) {
      getQuestion(lang);
    }
  }, [lang, step]);

  //Sert à garder les réponses quand les boutons radio sont cliqués
  //Paramètres =>  option : le label, answer : true/false
  function handleClickRadioButton(option: Possibilitie, answer: boolean) {
    //Permet de trouver un élément avec le même id que l'élément passer en paramètre
    let existingId = -1;
    for (let i = 0; i < answersRadioBtn.length; i++) {
      if (answersRadioBtn[i].id === option.id) {
        existingId = i;
      }
    }
    //Si il n'y a pas encore d'élément avec cet id on l'ajoute
    if (existingId === -1) {
      setAnswersRadioBtn(
        answersRadioBtn.concat({
          id: option.id,
          title: option.title,
          answer: answer,
        })
      );
      //Si le nombre de réponses données est le même que le nombre de réponses qu'on s'attend à recevoir,
      // on peut enlever le message d'erreur s'il est affiché
      //-1 : Parce que le set ne sera pas setter tout de suite
      if (
        answersRadioBtn.length ===
        questions[noQuestion].possibilities.length - 1
      ) {
        if (!messageErrorHidden) setMessageErrorHidden(true);
      }
    }
    //Sinon on change la réponse
    else {
      answersRadioBtn[existingId].answer = answer;
    }
  }
  //Sert à mettre le choix de l'utilisateur dans le state userChoice lorsqu'on clique sur un bouton de choix
  function handleClickButtonChoice(choice: Possibilitie) {
    if (!messageErrorHidden) setMessageErrorHidden(true);
    setUserChoice(choice);
  }
  //Change les questions de type 1 quand on clique sur suivant
  function changeQuestionType1() {
    //On met la question et la réponse dans le tableau pour savoir qu'elle a été demandé
    questionsUser.push({
      num_question: noQuestion,
      time_question: timeQuestion,
      answer: userChoice,
    });
    //On remet le choix à sa valeur initiale
    setUserChoice({ id: 0, title: "" });
    //On retient les réponses et questions dans un cookie
    saveProgress && setCookie("questionsUser", JSON.stringify(questionsUser));
  }
  //Change les questions de type 2 quand on clique sur suivant
  function changeQuestionType2(): boolean {
    //Vérifie si il y a une réponse qui est "oui" donc true
    let indexYesChecked = -1;
    for (let i = 0; i < answersRadioBtn.length; i++) {
      if (answersRadioBtn[i].answer === true) {
        indexYesChecked = i;
      }
    }
    answersRadioBtn.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
    questionsUser.push({
      num_question: noQuestion,
      time_question: timeQuestion,
      answer: answersRadioBtn,
    });
    setAnswersRadioBtn([]);
    //On retient les réponses et questions dans un cookie
    saveProgress && setCookie("questionsUser", JSON.stringify(questionsUser));
    //Si on a trouvé aucune réponse qui est à true on return false
    if (indexYesChecked === -1) {
      return false;
    }
    //sinon on retourne true
    return true;
  }
  //Affiche le message de fin
  //Paramètre => message : message de fin à afficher
  function lastStep(message: Message) {
    //On ajoute la réponse si c'est un type 1, car ce n'est pas déjà fait contrairement au type 2
    if (questions[noQuestion].type === 1) {
      //On met la question et la réponse dans le tableau pour savoir qu'elle a été demandé
      questionsUser.push({
        num_question: noQuestion,
        time_question: timeQuestion,
        answer: userChoice,
      });
    }
    //Enlever les données des variables
    setMessageEnd(message);
    setSendDataFinished(false);
    //Envoit des données vers la bd
    sendQuestionsAndAnswers(message);
    setStep(3);
  }
  //Envoit des données vers la bd
  async function sendQuestionsAndAnswers(message: Message) {
    const data = await sendData(
      questions,
      message,
      questionsUser,
      tabIdRespondantQuestion
    );
    setUuidRespondant(data?.uuid_respondant);
    setIdRespondant(data?.id_respondant);
    //On a fini d'envoyer les données
    setSendDataFinished(true);
    setSaveProgress(false);

    deleteCookie("noQuestion");
    deleteCookie("step");
    deleteCookie("questionsUser");
    deleteCookie("answers");
  }
  //Cas récurrent qui passe à la prochaine question quand la réponse est non et affiche le message si la réponse est oui
  function changeToQuestionOrLastStepIfYes() {
    if (questions[noQuestion].type === 1) {
      if (
        questions[noQuestion].messages.length >= 1 &&
        (userChoice.title === "Oui" || userChoice.title === "Yes")
      ) {
        lastStep(questions[noQuestion].messages[0]);
      } else {
        changeQuestionType1();
      }
    } else {
      let yesChecked: boolean;
      yesChecked = changeQuestionType2();
      if (questions[noQuestion].messages.length >= 1 && yesChecked) {
        lastStep(questions[noQuestion].messages[0]);
      }
    }
  }
  //Sert à changer à la bonne question selon les réponses quand on clique sur suivant
  function clickOnNext() {
    //Permet de savoir combien de temps ça a pris pour répondre à la question
    //(En faisant la différence entre le timestamp d'en ce moment et celui lorsque la question a débuté,
    // on peut savoir combien de temps l'utilisateur a pris pour répondre à la question)
    //On divise par 1000 pour avoir le temps en secondes
    timeQuestion = (Date.now() - timestampBeginning) / 1000;
    setTimestampBeginning(Date.now());
    window.scrollTo(0, 0);
    //On remet le choix à sa valeur initiale afin qu'aucun bouton ne soit sélectionné par défaut lorsqu'on fait suivant
    setUserChoice({ id: 0, title: "" });
    if (
      (questions[noQuestion].type === 1 && userChoice.id === 0) ||
      (questions[noQuestion].type === 2 &&
        answersRadioBtn.length !== questions[noQuestion].possibilities.length)
    ) {
      setMessageErrorHidden(false);
    } else {
      //Si le message est encore affiché quand on clique sur suivant, on l'enlève
      if (!messageErrorHidden) setMessageErrorHidden(true);
      //La fonction sag retourne le numéro de la prochaine question selon la question à laquelle on est rendu et la réponse
      //on y passe les variables du state nécessaires pour déterminer la prochaine question ainsi que les fonctions ci-haut
      //servant à ne pas répéter le même code plusieurs fois lorsqu'on doit changer le state lors du changement de question
      setNoQuestion(
        algo(
          noQuestion,
          userChoice,
          questionsUser,
          questions,
          changeQuestionType1,
          changeQuestionType2,
          lastStep,
          changeToQuestionOrLastStepIfYes
        )
      );
    }
  }
  //Sert à revenir à la question précédente quand on clique sur retour
  function clickOnBack() {
    window.scrollTo(0, 0);
    //Si le message d'erreur est encore affiché, on l'enlève
    if (!messageErrorHidden) setMessageErrorHidden(true);
    if (step === 3) {
      DeleteAnswersApi.deleteUserData(uuidRespondant, tabIdRespondantQuestion);
      setStep(2);
    }
    if (noQuestion !== 0 || step === 3) {
      //fait en sorte que l'on ne garde pas en mémoires des réponses qui ne sont plus vraies
      //Si on ne fait pas ça lorsque l'on fait retour sur une question de type 2 lorsqu'on va faire
      //suivant, les réponses vont être toujours là
      setAnswersRadioBtn([]);
      //L'index des réponses dans la variable answers est le même que l'index de la question dans la variable questionsUser
      //Comme ça on peut associer la question à sa réponse
      let indexAnswer = questionsUser.length - 1;
      let indexQuestion = questionsUser[indexAnswer].num_question;
      //On change le numéro de la question grâce au tableau qui contient les numéros de questions
      setNoQuestion(indexQuestion);
      if (questions[indexQuestion].type === 2) {
        //On met la réponse qui avait été sélectionnée
        setAnswersRadioBtn(questionsUser[indexAnswer].answer);
      } else {
        //On met la réponse qui avait été sélectionnée
        setUserChoice(questionsUser[indexAnswer].answer);
      }
      //on enlève le temps déjà écoulé au timestamp pour que lorsque l'utilisateur revienne sur une question,
      //on puisse ajouter le temps qu'il prend à celui qu'il a déjà pris
      setTimestampBeginning(
        Date.now() - questionsUser[indexAnswer].time_question * 1000
      );
      questionsUser.pop();
      //On met les nouvelles valeurs dans les cookies
      saveProgress && setCookie("questionsUser", JSON.stringify(questionsUser));
      saveProgress && setCookie("noQuestion", indexQuestion.toString());
    } else {
      //On enlève les réponses qui ont pu être sélectionnées
      setUserChoice({ id: 0, title: "" });
      saveProgress && setCookie("step", "1");
      setStep(1);
    }
  }
  //Quand on clique sur le bouton accueil
  function handleClickBackHome() {
    //On remet la première question avant de changer d'étape
    setNoQuestion(0);
    //On retourne à l'accueil
    setStep(1);
    if (lang === "fr") {
      alert(
        "Veuillez donner votre identifiant à l'infirmière si ce n'est pas déjà fait. Vous êtes le participant #" +
          idRespondant +
          "."
      );
    } else {
      alert(
        "Please give your ID to the nurse if you haven't already done so. You're the participant #" +
          idRespondant +
          "."
      );
    }
  }
  //Quand on clique sur le bouton Commencer, on affiche le questionnaire
  function handleClickBtnStart(lang: string) {
    //Nettoyer les variables ou cas où il y aurait encore des choses dedans
    setAnswersRadioBtn([]);
    setUserChoice({ id: 0, title: "" });
    setTimestampBeginning(0);
    setSaveProgress(true);

    setCookie("step", "2");
    setCookie("lang", lang);

    setLang(lang);
    setStep(2);
    getQuestion(lang);
  }
  //Permet de cacher le message quand l'animation est terminé
  function handleAnimationEnd() {
    setMessageErrorHidden(true);
  }
  //Permet d'afficher l'étape à laquelle on est rendu
  function changeSteps() {
    let loading = lang === "fr" ? "Chargement..." : "Loading...";
    if (step === 1) {
      questionsUser = [];
      return <Welcome handleClickBtnStart={handleClickBtnStart} />;
    } else if (step === 2) {
      //Si les questions ne sont pas encore dans la variable questions, on montre à l'utilisateur que ça charge
      if (questions.length === 0) {
        return <p className="loading">{loading}</p>;
      } else {
        if (timestampBeginning === 0) {
          setTimestampBeginning(Date.now());
        }
        return (
          <div className="container">
            <div className="row justify-content-center">
              <Block
                lang={lang}
                hidden={messageErrorHidden}
                handleAnimationEnd={handleAnimationEnd}
              />
              <Question
                lang={lang}
                answersRadioBtn={answersRadioBtn}
                question={questions[noQuestion]}
                handleClickButtonChoice={handleClickButtonChoice}
                handleClickRadioButton={handleClickRadioButton}
                clickOnBack={clickOnBack}
                clickOnNext={clickOnNext}
                userChoice={userChoice}
              />
            </div>
          </div>
        );
      }
    } else if (step === 3) {
      //Si les réponses ne sont pas encore envoyées à la, on montre à l'utilisateur que ça charge
      if (sendDataFinished === false) {
        return <p className="loading">{loading}</p>;
      } else {
        return (
          <div>
            <End message={messageEnd} lang={lang} idRespondant={idRespondant} />
            <div className="btn-position">
              <ButtonBack lang={lang} handleClick={clickOnBack} />
              <ButtonGoHome lang={lang} handleClick={handleClickBackHome} />
            </div>
          </div>
        );
      }
    }
  }
  return <div>{changeSteps()}</div>;
}

export default Questionnaire;
