function setCookie(name: string, val: string) {
    const date = new Date();
    //esacpe : permet d'encoder les caractères spéciaux (Je l'utilise, car j'ai des virgules dans les expressions que
    // je met dans les cookies et celles-ci sont considérées comme des séparateurs et si elles ne sont pas encodés, ça créé un bug)
    const value = escape(val);

    // Expire dans 7 jours
    date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));
    // Set it
    document.cookie = name+"="+value+"; expires="+date.toUTCString()+"; path=/;SameSite=strict";
}

function getCookie(name: string) {
    const value = "; " + document.cookie;
    // unescape : on décode la valeur du cookie pour avoir les caractères spéciaux
    const parts = (unescape(value)).split("; " + name + "=");
    
    if (parts.length === 2) {
        // @ts-ignore
        return parts.pop().split(";").shift();
    }
}

function deleteCookie(name: string) {
    const date = new Date();

    // Expire dans -1 jour
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name+"=; expires="+date.toUTCString()+"; path=/;SameSite=strict";
}
export {setCookie, getCookie, deleteCookie};