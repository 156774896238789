import * as React from "react";
import "../css/Buttons.css";
import { useState } from "react";

interface ButtonStartProps {
  handleClick: Function;
  lang: string;
}

function ButtonStart(props: ButtonStartProps) {
  let [classes, setClasses] = useState("buttonStart buttonNotClicked");
  let textBtn = props.lang === "fr" ? "Commencer" : "Start";
  function changeStyle() {
    setClasses("buttonStart buttonClicked");
  }
  function changeBackStyle() {
    setClasses("buttonStart buttonNotClicked");
  }
  return (
    <button
      onTouchStart={() => changeStyle()}
      onTouchEnd={() => changeBackStyle()}
      onClick={() => props.handleClick()}
      className={classes}
    >
      {textBtn}
    </button>
  );
}
export default ButtonStart;
