import * as React from "react";
import "../css/Buttons.css";
import { useState } from "react";

interface ButtonBackProps {
  handleClick: Function;
  lang: string;
}

function ButtonBack(props: ButtonBackProps) {
  let txtBtn = props.lang === "fr" ? "Retour" : "Back";
  let [styleMouseOver, setStyleMouseOver] = useState("btnBackNext");
  function changeStyle() {
    setStyleMouseOver("btnBackNext btnBackNextMouseOver");
  }
  function changeBackStyle() {
    setStyleMouseOver("btnBackNext");
  }
  return (
    <div
      onTouchStart={() => changeStyle()}
      onTouchEnd={() => changeBackStyle()}
      onClick={() => {
        props.handleClick();
        changeBackStyle();
      }}
      onMouseOver={() => changeStyle()}
      onMouseLeave={() => changeBackStyle()}
      onFocus={() => changeStyle()}
      onBlur={() => changeBackStyle()}
    >
      <button
        tabIndex={-1}
        aria-hidden={true}
        name="retour"
        className={styleMouseOver}
      >
        <span className="txtInBtnBack">&#8249;</span>
      </button>
      <button className="txtBtnBackNext txtMarginTextBack">{txtBtn}</button>
    </div>
  );
}

export default ButtonBack;
