import React from "react";
import Choices from "./Choices";
import ButtonNext from "./ButtonNext";
import ButtonBack from "./ButtonBack";
import "../css/Question.css";
import question from "../models/Question";
import Possibilitie from "../models/Possibilitie";
import AnswerRadioButton from "../models/AnswerRadioButton";

interface QuestionProps {
  question: question;
  handleClickButtonChoice: Function;
  handleClickRadioButton: Function;
  answersRadioBtn: AnswerRadioButton[];
  clickOnBack: Function;
  clickOnNext: Function;
  userChoice: Possibilitie;
  lang: string;
}

function Question(props: QuestionProps) {
  let styleTitle = "title marginType1";
  let styleDiv = "d-flex justify-content-center";
  let styleButtons = "row backNextPosition mr-0 ml-0 container-flex";
  if (props.question.type === 2) {
    styleTitle = "title marginTitleType2";
    styleDiv = "";
    styleButtons = "row backNextPosition mr-0 ml-0";
  }
  return (
    <div className="marginContainer">
      <h1 className={styleTitle}>{props.question.title}</h1>
      <div className={styleDiv}>
        <div className="column">
          <Choices
            lang={props.lang}
            answersRadioBtn={props.answersRadioBtn}
            idBtnClicked={
              props.userChoice === undefined ? 0 : props.userChoice.id
            }
            handleClickRadioButton={props.handleClickRadioButton}
            answers={props.question.possibilities}
            type={props.question.type}
            handleClickButtonChoice={props.handleClickButtonChoice}
          />
          <div className={styleButtons}>
            <ButtonBack lang={props.lang} handleClick={props.clickOnBack} />
            <ButtonNext
              lang={props.lang}
              answersRadioBtn={props.answersRadioBtn}
              lengthOpt={props.question.possibilities.length}
              handleClick={props.clickOnNext}
              userChoice={props.userChoice}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Question;
