import * as React from "react";
import "../css/Buttons.css";
import Possibilitie from "../models/Possibilitie";
import AnswerRadioButton from "../models/AnswerRadioButton";

interface ButtonNextProps {
  userChoice: Possibilitie;
  answersRadioBtn: AnswerRadioButton[];
  lengthOpt: number;
  handleClick: Function;
  lang: string;
}
function ButtonNext(props: ButtonNextProps) {
  let txtBtn = props.lang === "fr" ? "Suivant" : "Next";
  //Permet de rendre le bouton bleu et opaque à 100% si une réponse est sélectionnée
  let styleMouseOver = "btnBackNext btnBackNextMouseOver";
  let opacity = "1";
  if (
    props.userChoice.id === 0 &&
    props.answersRadioBtn.length < props.lengthOpt
  ) {
    opacity = "0.25";
    styleMouseOver = "btnBackNext";
  }
  return (
    <div
      style={{ opacity: opacity }}
      className="buttonsBackNextWithText marginBetweenBtnBackNext"
    >
      <div onClick={() => props.handleClick()}>
        <button className="txtMarginTextNext txtBtnBackNext">{txtBtn}</button>
        <button aria-hidden={true} tabIndex={-1} className={styleMouseOver}>
          <span className="txtInBtnNext align-middle">&#8250;</span>
        </button>
      </div>
    </div>
  );
}

export default ButtonNext;
