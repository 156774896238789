import * as React from "react";
import "../css/Buttons.css";
import { useState } from "react";

interface ButtonGoHomeProps {
  handleClick: Function;
  lang: string;
}
function ButtonGoHome(props: ButtonGoHomeProps) {
  let txtBtn = props.lang === "fr" ? "Accueil" : "Home";
  let [styleMouseOver, setStyleMouseOver] = useState("btnBackNext");
  function changeStyle() {
    setStyleMouseOver("btnBackNext btnBackNextMouseOver");
  }
  function changeBackStyle() {
    setStyleMouseOver("btnBackNext");
  }
  return (
    <div className="buttonsBackNextWithText marginBetweenBtnBackNext">
      <div onClick={() => props.handleClick()}>
        <button className="txtMarginTextNext txtBtnBackNext">{txtBtn}</button>
        <button
          onTouchStart={() => changeStyle()}
          onTouchEnd={() => changeBackStyle()}
          onMouseOver={() => changeStyle()}
          onMouseLeave={() => changeBackStyle()}
          onFocus={() => changeStyle()}
          onBlur={() => changeBackStyle()}
          aria-hidden={true}
          tabIndex={-1}
          className={styleMouseOver}
        >
          <span className="txtInBtnNext align-middle">&#8250;</span>
        </button>
      </div>
    </div>
  );
}

export default ButtonGoHome;
