import * as React from "react";
import "../css/Buttons.css";
import Possibilitie from "../models/Possibilitie";

interface ButtonChoiceProps {
  choice: Possibilitie;
  handleClick: Function;
  idBtnClicked: number;
}

function ButtonChoice(props: ButtonChoiceProps) {
  let classes = "button buttonNotClicked";
  //Permet de changer l'apparence du bouton lorsqu'il est cliqué
  //et de le remettre comme avant si un autre bouton est cliqué
  if (props.idBtnClicked === props.choice.id) {
    classes = "button buttonClicked";
  }
  return (
    <div>
      <button
        onClick={() => props.handleClick(props.choice)}
        className={classes}
      >
        {props.choice.title}
      </button>
    </div>
  );
}

export default ButtonChoice;
