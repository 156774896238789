import React from "react";
import "../css/End.css";
import Message from "../models/Message";
interface EndProps {
  message: Message;
  lang: string;
  idRespondant: number;
}
function End(props: EndProps) {
  let endMessage =
    props.lang === "fr"
      ? "Merci d'avoir complété le questionnaire."
      : "Thank you for completing the questionnaire.";
  let patientMessage =
    props.lang === "fr" ? "Vous êtes le patient #" : "You are the patient #";
  const endingMsg = process.env.REACT_APP_ENDING_MESSAGE;
  return (
    <div className="msgContainer">
      {typeof endingMsg !== undefined && endingMsg?.toString() === "true" ? (
        <p className="msg">{props.message.message}</p>
      ) : (
        <p className="msg">{endMessage}</p>
      )}
      <p>
        {patientMessage}
        {props.idRespondant}.
      </p>
    </div>
  );
}

export default End;
