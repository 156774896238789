import React from "react";
import ButtonStart from "./ButtonStart";
import logo from "../images/logo1_influence.png";
import "../css/Welcome.css";
import { getCookie } from "../Cookies";
import logo_CHU from "../images/LOGO_CHU_UL_RVB_110x112.jpg";
import logo_CRI from "../images/logoCRI_modifie_110x43.png";
interface WelcomeProps {
  handleClickBtnStart: Function;
}
function Welcome(props: WelcomeProps) {
  //Permet d'aller chercher le cookie qui contient la langue choisit par l'utilisateur s'il existe
  let cookieLang = getCookie("lang");
  //Permet de setter la langue
  let [lang, setLang] = React.useState(
    cookieLang === undefined ? "fr" : cookieLang
  );
  //Permet de changer le message de l'accueil pour anglais ou français
  let [welcomeMessage, setWelcomeMessage] = React.useState(
    cookieLang === undefined || cookieLang === "fr"
      ? [
          "Un outil pour vous aider à",
          "prendre de bonnes décisions",
          "si vous pensez avoir la ",
          "grippe",
          "Le questionnaire ne prendra qu’une ou deux minutes de votre temps.",
        ]
      : [
          "A tool to help you",
          "make the right decisions",
          "if you think you have the ",
          "flu",
          "The questionnaire only takes a minute or two of your time.",
        ]
  );
  function changeSelect(event: any) {
    setLang(event.target.value);
    if (event.target.value === "fr") {
      setWelcomeMessage([
        "Un outil pour vous aider à",
        "prendre de bonnes décisions",
        "si vous pensez avoir la ",
        "grippe",
        "Le questionnaire ne prend qu’une ou deux minutes de votre temps.",
      ]);
    } else {
      setWelcomeMessage([
        "A tool to help you",
        "make the right decisions",
        "if you think you have the ",
        "flu",
        "The questionnaire will take a minute or two of your time.",
      ]);
    }
  }
  function placeElements() {
    if (document.documentElement.clientWidth <= 693) {
      return (
        <div className="contain d-flex row align-items-end justify-content-center">
          <div className="d-flex flex-column">
            <div className="logoCHU_select_div">
              <div>
                <img className="logoCHU" src={logo_CHU} alt="Le logo du CHU" />
                <img
                  className="logoCRI"
                  src={logo_CRI}
                  alt="Le logo du Centre de recherche en infectiologie"
                />
              </div>
              <div className="positionSelect">
                <select
                  name="lang"
                  className="selectpicker"
                  onChange={(event) => changeSelect(event)}
                  defaultValue={lang}
                >
                  <option value="fr">Français</option>
                  <option value="eng">English</option>
                </select>
              </div>
            </div>
            <img className="logo marginLogo" src={logo} alt="Le logo" />
          </div>
          <div className="d-flex flex-column">
            <h1 className="titleWelcome">
              {welcomeMessage[0]}
              <br aria-hidden={true} />
              {welcomeMessage[1]}
              <br aria-hidden={true} />
              {welcomeMessage[2]}
              <strong>{welcomeMessage[3]}</strong>.
            </h1>
            <p className="timeMessage">{welcomeMessage[4]}</p>
            <p className="versionNumber">1.1</p>
            <ButtonStart
              lang={lang}
              handleClick={() => props.handleClickBtnStart(lang)}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="contain">
          <div className="logoCHU_div">
            <img
              src={logo_CRI}
              alt="Le logo du Centre de recherche en infectiologie"
            />
            <div className="logoCHU_div">
              <img src={logo_CHU} alt="Le logo du CHU" />
            </div>
          </div>
          <div className="d-flex align-items-end justify-content-center">
            <div className="d-flex flex-column">
              <img
                className="logo marginLogo"
                src={logo}
                alt="Le logo du projet Influence"
              />
            </div>
            <div className="d-flex flex-column start_div">
              <div className="positionSelect">
                <select
                  name="lang"
                  className="selectpicker"
                  onChange={(event) => changeSelect(event)}
                  defaultValue={lang}
                >
                  <option value="fr">Français</option>
                  <option value="eng">English</option>
                </select>
              </div>
              <h1 className="titleWelcome">
                {welcomeMessage[0]}
                <br aria-hidden={true} />
                {welcomeMessage[1]}
                <br aria-hidden={true} />
                {welcomeMessage[2]}
                <strong>{welcomeMessage[3]}</strong>.
              </h1>
              <p className="timeMessage">{welcomeMessage[4]}</p>
              <p className="versionNumber">1.1</p>
              <ButtonStart
                lang={lang}
                handleClick={() => props.handleClickBtnStart(lang)}
              />
            </div>
          </div>
        </div>
      );
    }
  }
  return <React.Fragment>{placeElements()}</React.Fragment>;
}

export default Welcome;
