import AxiosInstance from './AxiosInstance';

async function deleteUserData(uuid_respondant: any, id_respondant_question: Array<number>) {
    const response = await AxiosInstance.post('/respondant-delete/delete-answers', { uuid_respondant, id_respondant_question});

    return response;
}

export default {
    deleteUserData
}