import AxiosInstance from './AxiosInstance';
import Question from '../models/Question';

async function getQuestions(lang : string): Promise<Question[]> {
    const response = await AxiosInstance.get('/questions/'+ lang);
    return await response.data;
}

export default {
    getQuestions
}