import RespondantApi from "./apis/RespondantApi";
import RespondantQuestionApi from "./apis/RespondantQuestionApi";
import RespondantAnswerApi from "./apis/RespondantAnswerApi";
import question from "./models/Question";
import Message from "./models/Message";
import Result from "./models/Result";
//Sert à envoyer les données dans la bd (Questions et réponses associsé à un répondant)
export async function sendData(
  questions: question[],
  message: Message,
  questionsUser: Array<Result>,
  tabIdRespondantQuestion: Array<number>
): Promise<any> {
  //Création du répondant dans la bd
  const data = await createNewRespondant(message, questionsUser);
  const uuid_respondant = data?.uuid_respondant;
  let id_respondant_question = 0;
  //On parcourt les questions qui ont été répondu
  for (let i = 0; i < questionsUser.length; i++) {
    let idQuestion = questions[questionsUser[i].num_question].id;
    //ENvoit de la question
    id_respondant_question = await sendQuestions(
      uuid_respondant,
      idQuestion,
      questionsUser[i].time_question
    );
    tabIdRespondantQuestion[i] = id_respondant_question;
    //Si c'est une question de type 1, la réponse du champ "answer" de la bd sera "" sinon le ce sera "Oui" ou "Non"
    let answer: string = "";
    //On parccourt les réponses s'il y en a plusieurs
    if (questionsUser[i].answer.length > 0) {
      for (let k = 0; k < questionsUser[i].answer.length; k++) {
        answer = questionsUser[i].answer[k].answer ? "Oui" : "Non";
        //Envoit de la réponse (Question type 2)
        await sendAnswer(
          questionsUser[i].answer[k].id,
          answer,
          id_respondant_question
        );
      }
    } else {
      //Envoit de la réponse (Question type 1)
      await sendAnswer(
        questionsUser[i].answer.id,
        answer,
        id_respondant_question
      );
    }
  }
  return data;
}
//Sert à créer un nouveau répondant pour pouvoir y associer les questions et réponses
export async function createNewRespondant(
  message: Message,
  questionsUser: Array<Result>
) {
  let totalTime: number = 0;
  //Calcul du temps total des questionnaire en additionnant le temps des questions
  for (let i = 0; i < questionsUser.length; i++) {
    totalTime += questionsUser[i].time_question;
  }
  let totalTimeRounded = Math.round((totalTime + Number.EPSILON) * 100) / 100;
  const response = await RespondantApi.createRespondant(
    totalTimeRounded,
    message.id
  );
  if (response.status !== 201) {
    alert("Insert error");
    return;
  }
  const data = {
    id_respondant: response.data.id_respondant,
    uuid_respondant: response.data.uuid_respondant,
  };
  return data;
}
//Sert à envoyer les questions vers la bd
export async function sendQuestions(
  id_respondant: any,
  id_question: number,
  time_question: number
) {
  let timeRounded = Math.round((time_question + Number.EPSILON) * 100) / 100;
  const response = await RespondantQuestionApi.sendQuestion(
    id_respondant,
    id_question,
    timeRounded
  );
  if (response.status !== 201) {
    alert("Insert error");
    return;
  }
  return response.data.id_respondant_question;
}
//Sert à envoyer les réponses vers la bd
export async function sendAnswer(
  id_question_answer: number,
  answer: string,
  id_respondant_question: number
) {
  const response = await RespondantAnswerApi.sendAnswer(
    id_question_answer,
    answer,
    id_respondant_question
  );
  if (response.status !== 201) {
    alert("Insert error");
    return;
  }
}
export default sendData;
