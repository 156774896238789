import React from "react";
import ButtonChoice from "./ButtonChoice";
import RadioButton from "./RadioButton";
import "../css/RadioButton.css";
import "../css/Choices.css";
import Possibilitie from "../models/Possibilitie";
import AnswerRadioButton from "../models/AnswerRadioButton";

interface ChoicesProps {
  answers: Array<Possibilitie>;
  type: number;
  handleClickButtonChoice: Function;
  handleClickRadioButton: Function;
  idBtnClicked: number;
  answersRadioBtn: AnswerRadioButton[];
  lang: string;
}

function Choices(props: ChoicesProps) {
  let txtYes = props.lang === "fr" ? "Oui" : "Yes";
  let txtNo = props.lang === "fr" ? "Non" : "No";
  //Selon le type de question elle retourne une liste de boutons avec les réponses ou une liste de boutons radio avec les réponses
  function RadioButtonOrButton() {
    if (props.type === 1) {
      return (
        <div>
          {props.answers.map((value) => {
            return (
              <ButtonChoice
                idBtnClicked={props.idBtnClicked}
                key={value.id}
                choice={value}
                handleClick={props.handleClickButtonChoice}
              />
            );
          })}
        </div>
      );
    } else {
      return (
        <fieldset role="radiogroup" className="container containerRadioBtn">
          <div className="rowWhitoutWrap">
            <div className="col-sm-8"></div>
            <div className="col-sm-4 marginBottomYesNo">
              <span className="yesNo marginYes">{txtYes}</span>
              <span className="yesNo marginNo">{txtNo}</span>
            </div>
          </div>
          <ul className="p-0">
            {props.answers.map((value) => {
              return (
                <RadioButton
                  answersRadioBtn={props.answersRadioBtn}
                  key={value.id}
                  handleClick={props.handleClickRadioButton}
                  choice={value}
                />
              );
            })}
          </ul>
        </fieldset>
      );
    }
  }
  return <div>{RadioButtonOrButton()}</div>;
}

export default Choices;
