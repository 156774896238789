import * as React from "react";
import "../css/Block.css";

interface BlockProps {
  hidden: boolean;
  handleAnimationEnd: Function;
  lang: string;
}

function Block(props: BlockProps) {
  let erreur =
    props.lang === "fr"
      ? "Merci de répondre à la question"
      : "Please answer the question";
  let styleAnimation = "center notVisible";
  let role = "";
  if (!props.hidden) {
    styleAnimation = "center animate__animated animate__fadeOut";
    role = "alert";
  }
  return (
    <div
      className={styleAnimation}
      onAnimationEnd={() => props.handleAnimationEnd()}
    >
      <div id="block" role={role}>
        {erreur}
      </div>
    </div>
  );
}

export default Block;
