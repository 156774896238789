import AxiosInstance from './AxiosInstance';

async function sendAnswer(id_question_answer: number, answer: string, id_respondant_question: number) {
    const response = await AxiosInstance.post('/respondant-answer/answer-question', { id_question_answer, answer, id_respondant_question});

    return response;
}

export default {
    sendAnswer
}