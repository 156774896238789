import * as React from "react";
import "../css/RadioButton.css";
import { useState } from "react";
import Possibilitie from "../models/Possibilitie";
import AnswerRadioButton from "../models/AnswerRadioButton";

interface ListeChoixProps {
  choice: Possibilitie;
  handleClick: Function;
  answersRadioBtn: AnswerRadioButton[];
}
function RadioButton(props: ListeChoixProps) {
  let indexOption = -1;
  for (let i = 0; i < props.answersRadioBtn.length; i++) {
    if (props.answersRadioBtn[i].id === props.choice.id) {
      indexOption = i;
    }
  }
  //Explication de la conditon : si answersRadioBtn n'a aucun élément ou que l'élément n'est pas défini, ça veut dire qu'aucun bouton n'est coché
  //sinon on coche oui on non selon la réponse qui se trouve dans la case voulue (props.choice.id - 1 correspond à l'index de la réponse cherchée)
  let [selectedOption, setSelectedOption] = useState(
    props.answersRadioBtn.length === 0 ||
      props.answersRadioBtn[indexOption] === undefined
      ? "none"
      : props.answersRadioBtn[indexOption].answer
      ? "yes"
      : "no"
  );
  //Si la valeur du tableau de réponses des radio bouton est différente que celle du state, on update le state
  if (
    !(
      props.answersRadioBtn.length === 0 ||
      props.answersRadioBtn[indexOption] === undefined
    )
  ) {
    if (
      selectedOption !==
      (props.answersRadioBtn[indexOption].answer ? "yes" : "no")
    ) {
      setSelectedOption(
        props.answersRadioBtn[indexOption].answer ? "yes" : "no"
      );
    }
  }
  function handleSelection(event: any) {
    setSelectedOption(event.target.value);
  }
  return (
    <React.Fragment>
      <div className="rowWhitoutWrap">
        <label className="col-sm-8 txtLabel">{props.choice.title}</label>
        <div
          className="col-sm-4 marginBottomRadioBtn"
          onChange={(event) => handleSelection(event)}
          onKeyPress={(event) => handleSelection(event)}
        >
          <label className="checkContainer d-inline marginRightRadioBtn">
            <input
              tabIndex={-1}
              checked={selectedOption === "yes" && indexOption !== -1}
              type="radio"
              value="yes"
              name={props.choice.title}
              onChange={() => props.handleClick(props.choice, true)}
              aria-hidden={true}
            />
            <span
              role="radio"
              aria-label={props.choice.title + " oui"}
              tabIndex={0}
              aria-checked={selectedOption === "yes" && indexOption !== -1}
              onKeyPress={() => props.handleClick(props.choice, true)}
              className="radiobtn"
            ></span>
          </label>
          <label className="checkContainer d-inline">
            <input
              tabIndex={-1}
              checked={selectedOption === "no" && indexOption !== -1}
              type="radio"
              value="no"
              name={props.choice.title}
              onChange={() => props.handleClick(props.choice, false)}
              aria-hidden={true}
            />
            <span
              role="radio"
              aria-label={props.choice.title + " non"}
              tabIndex={0}
              aria-checked={selectedOption === "no" && indexOption !== -1}
              onKeyPress={() => props.handleClick(props.choice, false)}
              className="radiobtn"
            ></span>
          </label>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RadioButton;
