import AxiosInstance from './AxiosInstance';

async function createRespondant(time_questionnaire: number, id_messageEnd: number) {
    const response = await AxiosInstance.post('/respondant/create-respondant', { time_questionnaire, id_messageEnd});

    return response;
}

export default {
    createRespondant
}